//* External
import { isArray, pickBy } from 'lodash';
import moment from 'moment-timezone';

//* App Custom
import { tnl } from '@i18nApp/i18n';

const DATE_FORMATS = {
  DAY: 'DD/MM/YYYY',
  HOUR: 'h:mm A',
  FULL_DATE: 'DD/MM/YYYY hh:mm A'
};
export const REMOVE_FILTER_SELECT = {
  label: 'components.input_select_remove_filter',
  value: ''
};

const formatDate = (date, format = DATE_FORMATS.DAY) => {
  return date
    ? moment(date)
        .tz(import.meta.env.VITE_TIME_ZONE)
        .format(format)
    : '';
};

const calculateAge = (date) => {
  if (date) return moment().diff(date, 'years');
};

const formatDateForInput = (date, format = DATE_FORMATS.DAY) => {
  const isDate = new Date(date);
  let dateToReturn = '';
  if (!isNaN(isDate)) {
    dateToReturn = moment(isDate)
      .tz(import.meta.env.VITE_TIME_ZONE)
      .format();
    if (format === DATE_FORMATS.DAY) {
      const [day] = dateToReturn.split('T');
      dateToReturn = day;
    } else if (format === DATE_FORMATS.FULL_DATE) {
      dateToReturn = dateToReturn.slice(0, -9);
    }
  }
  return dateToReturn;
};

const isAllDataNull = (arrayData) =>
  arrayData.every((data) => data === null || data === undefined || data === '');

const verifyData = (arrayData, separator = ' ') => {
  if (isAllDataNull(arrayData)) return ' - ';
  else return arrayData.join(separator);
};

const cleanObject = (data) =>
  pickBy(data, (value) => {
    if (isArray(value) && value?.length !== 0) return true;
    if (!isArray(value) && !!value) return true;
    return false;
  });

const cleanArray = (data) => data.filter((el) => el != null);

const cleanArrayOfObjects = (data) =>
  data?.filter((obj) => Object.values(obj).every((el) => !!el));

const turnIntoFormData = (data, multipleName = '') => {
  let formData = new FormData();
  if (!Array.isArray(data)) {
    Object.entries(data).forEach((entry) => {
      if (entry[1]) {
        formData.append(entry[0], entry[1]);
      }
    });
  } else {
    data.map((item, index) => {
      Object.entries(item).forEach((entry) => {
        if (entry[1]) {
          formData.append(`${multipleName}[${index}]${entry[0]}`, entry[1]);
        }
      });
    });
  }
  return formData;
};

const createDropdownOptions = (array) =>
  array.map((item) => ({
    label: tnl(item),
    value: item
  }));

const newApplicationFromFrontend = (application) => {
  if (typeof application.pk === 'string') {
    return application.pk.split('_')[0];
  } else return null;
};

const calculateColumnsWidth = ({
  componentsAdded,
  space = 80,
  width = 150
}) => {
  const newWidth = space * componentsAdded + width;
  return `${newWidth}px`;
};

const downloadServerFile = (response, filename) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const redirectServerFile = (response) => {
  const link = document.createElement('a');
  link.target = '_blank';
  window.open(response.data.report_file);
};

export {
  DATE_FORMATS,
  calculateAge,
  calculateColumnsWidth,
  cleanArray,
  cleanArrayOfObjects,
  cleanObject,
  createDropdownOptions,
  downloadServerFile,
  formatDate,
  formatDateForInput,
  newApplicationFromFrontend,
  redirectServerFile,
  turnIntoFormData,
  verifyData
};

export { default as REGEX_OPTIONS } from './regexValidations';
export { default as sendRequest } from './sendRequest';

export * from './navigationHandler';
