//* External
import PropTypes from 'prop-types';
import { Dropdown, DropdownButton as BDropdownButton } from 'react-bootstrap';
import { tnl } from '@i18nApp/i18n';

//* App Custom
import '../button.scss';

const DropdownButton = ({
  actions,
  text,
  disabled = false,
  actionProps,
  drop
}) => {
  let actionsArray = [];
  if (typeof actions === 'function') {
    actionsArray = actionProps ? actions(actionProps) : [];
  } else {
    actionsArray = actions;
  }

  return (
    <BDropdownButton
      disabled={disabled}
      id="dropdown-basic-button"
      title={text || ''}
      drop={drop || 'down-centered'}
    >
      {actionsArray
        .filter((action) => !action?.isHidden)
        .map((action, index) => (
          <Dropdown.Item
            key={`item_${index}`}
            className={action.state && `dropdown-item-${action.state}`}
            onClick={action.onClick}
          >
            {tnl(action.label)}
          </Dropdown.Item>
        ))}
    </BDropdownButton>
  );
};

DropdownButton.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        state: PropTypes.oneOf(['success', 'fail', 'info']),
        isHidden: PropTypes.bool
      })
    ),
    PropTypes.func
  ]).isRequired,
  actionProps: PropTypes.object,
  drop: PropTypes.oneOf([
    'up',
    'up-centered',
    'start',
    'end',
    'down',
    'down-centered'
  ])
};

export default DropdownButton;
