//* External
import Lottie from 'lottie-react';
import { Suspense, lazy } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';

//* App Custom
import loader from '@assetsApp/loader.json';
import { ROUTES } from '@constantsApp/index';
import { useLocalStorage } from '@hooksApp/index';
import { privateRouteOptions } from '@utilsApp/navigationHandler';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import PublicRoute from './PublicRoute/PublicRoute';

const Routes = () => {
  const { sidebarData } = useLocalStorage();

  const LandingPage = lazy(() =>
    import('@pagesApp/Public/LandingPage/LandingPage')
  );
  const LoginPage = lazy(() => import('@pagesApp/Public/Login/LoginPage'));
  const Error404 = lazy(() => import('@pagesApp/Error404/Error404'));

  const UserAuthenticated = lazy(() =>
    import('@layoutsApp/UserAuthenticated/UserAuthenticated')
  );
  const Home = lazy(() => import('@pagesApp/Private/Home/Home'));
  const Dashboard = lazy(() => import('@pagesApp/Private/Dashboard/Dashboard'));
  const PatientManagement = lazy(() =>
    import('@pagesApp/Private/PatientManagement/PatientManagement')
  );
  const PatientConfirmed = lazy(() =>
    import('@pagesApp/Private/PatientConfirmed/PatientConfirmed')
  );
  const Applications = lazy(() =>
    import('@pagesApp/Private/Applications/Applications')
  );
  const Rooms = lazy(() =>
    import('@pagesApp/Private/RoomsManagement/Rooms/Rooms')
  );
  const Chair = lazy(() =>
    import('@pagesApp/Private/RoomsManagement/Chair/Chair')
  );
  const RoomChief = lazy(() =>
    import('@pagesApp/Private/RoomsManagement/RoomChief/RoomChief')
  );
  const Bills = lazy(() => import('@pagesApp/Private/Bills/Bills'));
  const Patients = lazy(() =>
    import('@pagesApp/Private/PatientsList/Patients/Patients')
  );
  const Patient = lazy(() =>
    import('@pagesApp/Private/PatientsList/Patient/Patient')
  );
  const ProgrammingProtocols = lazy(() =>
    import('@pagesApp/Private/ProgrammingProtocols/ProgrammingProtocols')
  );
  const Survey = lazy(() => import('@pagesApp/Private/Survey/Survey'));
  const WorkUnit = lazy(() => import('@pagesApp/Private/WorkUnit/WorkUnit'));

  const pageSelected = (name) => {
    const pageDirectory = {
      carmen_dashboard: Dashboard,
      csb_dashboard: Dashboard,
      cuc_dashboard: Dashboard,
      clinica_colombia_ordenamiento_diario: Dashboard,
      clinica_universitaria_colombia_ruta_preferencial_oncologica: Dashboard,
      indicador_oncologico: Dashboard,
      informe_gestion_ips_back_office: Dashboard,
      seguimiento_gestores_mitrol: Dashboard,
      biopsias_prostata: Dashboard,
      keralty_ca_mama_priorizado: Dashboard,
      keralty_ca_prostata_priorizado: Dashboard,
      keralty_mieloma: Dashboard,
      keralty_pulmon: Dashboard,
      ufca_gastrico: Dashboard,
      ufca_gastrico_seguimiento: Dashboard,
      ufca_mama_seguimiento: Dashboard,
      ufca_mieloma_seguimiento: Dashboard,
      ufca_prostata_seguimiento: Dashboard,
      ufca_pulmon_seguimiento: Dashboard,
      mama_following: Dashboard,
      prostrate_following: Dashboard,
      following_manager_oncology: Dashboard,
      patient_management: PatientManagement,
      applications: Applications,
      rooms: Rooms,
      room_chief: RoomChief,
      patient_confirmed: PatientConfirmed,
      bills: Bills,
      patients: Patients,
      programming_protocols: ProgrammingProtocols,
      ufca_mama: Survey,
      ufca_prostata: Survey,
      ufca_pulmon: Survey,
      ufca_estomago: Survey,
      ufca_colorectal: Survey,
      ufca_hepatico: Survey,
      ufca_peritoneo_y_retroperitoneo: Survey,
      ufca_vias_biliares: Survey,
      work_unit_ufca_mama: WorkUnit,
      ufca_mieloma: Survey,
      ufca_displasias: Survey,
      ufca_laparoscopias: Survey,
      ufca_sugarbaker: Survey,
      ufca_cervix: Survey,
      ufca_cervix_tamizaje: Survey,
      myeloma_following: Dashboard,
      cervix_following: Dashboard,
      colon_following: Dashboard,
      stomach_following: Dashboard,
      liver_following: Dashboard,
      leukemia_following: Dashboard,
      lymphoma_following: Dashboard,
      cancer_following: Dashboard,
      lung_following: Dashboard,
      bile_ducts_following: Dashboard,
      keralty_ca_mama_priorizado_cec: Dashboard,
      keralty_ca_prostata_priorizado_cec: Dashboard,
      ufca_gastrico_cec: Dashboard,
      ufca_gastrico_seguimiento_cec: Dashboard,
      ufca_mama_seguimiento_cec: Dashboard,
      ufca_prostata_seguimiento_cec: Dashboard,
      following_manager_cervix_cec: Dashboard,
      following_manager_oncology_cec: Dashboard,
      following_manager_colon_cec: Dashboard,
      following_manager_stomach_cec: Dashboard,
      following_manager_liver_cec: Dashboard,
      following_manager_breast_cec: Dashboard,
      following_manager_cancer_cec: Dashboard,
      following_manager_prostate_cec: Dashboard,
      following_manager_bile_ducts_cec: Dashboard,
      keralty_ca_mama_priorizado_csb: Dashboard,
      keralty_mieloma_csb: Dashboard,
      keralty_pulmon_csb: Dashboard,
      ufca_gastrico_csb: Dashboard,
      ufca_gastrico_seguimiento_csb: Dashboard,
      ufca_mama_seguimiento_csb: Dashboard,
      ufca_mieloma_seguimiento_csb: Dashboard,
      ufca_pulmon_seguimiento_csb: Dashboard,
      ufca_quirurgico: Survey,
      ufca_watch_wait: Survey,

    };
    const Page = pageDirectory[name];
    return <Page />;
  };

  const router = createBrowserRouter([
    {
      path: '*',
      element: <Navigate to={ROUTES.ERROR} />
    },
    {
      path: ROUTES.ERROR,
      element: <Error404 />
    },
    {
      path: ROUTES.ROOT,
      element: (
        <PublicRoute>
          <LandingPage />
        </PublicRoute>
      )
    },
    {
      path: ROUTES.LOGIN,
      element: (
        <PublicRoute>
          <LoginPage />
        </PublicRoute>
      )
    },
    {
      path: ROUTES.PLATFORM,
      element: <UserAuthenticated />,
      children: [
        {
          path: ROUTES.PLATFORM,
          element: (
            <PrivateRoute module={{ title: 'routes.home' }}>
              <Home />
            </PrivateRoute>
          )
        },
        ...privateRouteOptions(sidebarData).map((item) => ({
          path: `/platform/${item.functional_name}`,
          element: (
            <PrivateRoute module={{ title: item.label }}>
              {pageSelected(item.functional_name)}
            </PrivateRoute>
          )
        })),
        {
          path: ROUTES.CHAIR,
          element: (
            <PrivateRoute
              module={{
                title: 'routes.chair',
                breadcrumbs: [
                  {
                    route: ROUTES.ROOMS,
                    label: 'routes.rooms'
                  }
                ]
              }}
            >
              <Chair />
            </PrivateRoute>
          )
        },
        {
          path: ROUTES.PATIENT,
          element: (
            <PrivateRoute
              module={{
                title: 'routes.patient',
                breadcrumbs: [
                  {
                    route: ROUTES.PATIENTS,
                    label: 'routes.patients'
                  }
                ]
              }}
            >
              <Patient />
            </PrivateRoute>
          )
        }
      ]
    }
  ]);

  return (
    <Suspense
      fallback={
        <div className="loadingContainer">
          <Lottie animationData={loader} loop={true} />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;
