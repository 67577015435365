//* External
import FormCheck from 'react-bootstrap/FormCheck';
import { memo, useState } from 'react';

//* App Custom
import '../inputs.scss';
import { tnl } from '@i18nApp/i18n';

const InputSwitch = ({
  name,
  label,
  defaultValue,
  disabled = false,
  required = false,
  design = 'col-12',
  hookError,
  hookOnChange = () => {},
  onInputChange = () => {}
}) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <div className={design}>
      <FormCheck
        required={required}
        disabled={disabled}
        name={name}
        defaultChecked={defaultValue}
        label={tnl(label)}
        type="switch"
        id="custom-switch"
        onChange={() => {
          setValue(!value);
          hookOnChange(!value);
          onInputChange(!value);
        }}
      />
      {hookError?.message && (
        <p className="customInput_error">{tnl(hookError?.message)}</p>
      )}
    </div>
  );
};

const MemoizeSwitch = memo(InputSwitch);
export default MemoizeSwitch;
